import { fetchUtils } from 'react-admin'
import raStrapiRest from './ra-strapi-rest'

const strapiApiUrl = process.env.REACT_APP_API_URL || 'http://localhost:1337/api'
//const token = 'dcdfb57cd1cd3d2e1affb9a93bcc3be8bf9846255f9264b82d76e5be978b5518a56d5f7747f91f10863af2a14fbe04487c4b018cbedc86868bacd07e7a6469c7c754cf9c365f65f71101f4030d1eb09c11088fc0afc52c728abb6f51ff5a188b15008dbf7ccec8ac49f4d28a5d1f6da6fd47bf26bddda42513c42b6563d84bb1'


const httpClient = (url: string, options: any = {}) => {
  const token = localStorage.getItem('token')
  options.headers = options.headers || new Headers({ Accept: "application/json" })
  //options.headers.set("Authorization", `Bearer ${token}`)
  options.headers.set("Authorization", `${token}`)
  return fetchUtils.fetchJson(url, options)
}

export const dataProvider = raStrapiRest(strapiApiUrl, httpClient)