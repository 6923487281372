import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

import { RecordCreateForm } from './recordCreateForm'


export const RecordCreateModal = ({
  roomId, tableId, date, isOpen, setIsOpen
}: {
  roomId: number,
  tableId: number | null,
  date: Date | null,
  isOpen: boolean,
  setIsOpen: Function
}
) => {

  const handleSubmit = () => {
    setIsOpen(false)
  }
  
  return (
    <>
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Создать новую запись</DialogTitle>
      <DialogContent>
        <RecordCreateForm
          roomId={roomId}
          tableId={tableId}
          date={date}
          onSubmit={handleSubmit}
        />
      </DialogContent>
    </Dialog>
    </>
  )
}