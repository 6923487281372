import { useEffect, useMemo, useState } from 'react'
import { useDataProvider, SelectInput, SelectField, Form } from 'react-admin'
import { useParams } from 'react-router'
import * as _ from 'lodash'

import {
  CalendarContainer,
  CalendarTableListWrapper,
  CalendarContentWrapper,
  TimeLabelList,
  RecordList,
  RecordHourRow,
  RecordCell,
  RecordCellLabel,
  RecordBlockWrapper,
  RecordBlock,
  RecordInfoWrapper,
  DevicesWrapper,
  DeviceGroupWrapper,
  DeviceIconWrapper,
  NowTimeLineWrapper,
  NowTimeLine,
} from './styles'

import { ReactComponent as HangingLampOnSvg } from '../../assets/devices/svg/hanging-lamp-on.svg'
import { ReactComponent as HangingLampOffSvg } from '../../assets/devices/svg/hanging-lamp-off.svg'

import dayjs from 'dayjs'

import { RecordCreateModal } from './recordCreateModal'
import { RecordEditModal } from './recordEditModal'
import { ApiRecord, ApiTable, DeviceScript, Record } from '../../types/common'

const log = console.log

export const Calendar = ({ roomId }: { roomId: number }) => {
  const dp = useDataProvider()
  const { date } = useParams()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [records, setRecords] = useState<Record[]>([])
  const [tables, setTables] = useState<ApiTable[]>([])
  const [cellHeight, setCellHeight] = useState<number>(16)
  const [period, setPeriod] = useState<number>(30)
  const [offsetHours, setOffsetHours] = useState<number>(0)
  const [nowTime, setNowTime] = useState<number>(dayjs(dayjs().format('YYYY-MM-DD HH:mm')).unix())
  const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(null)
  const [selectedDate, setSelectedDate] = useState<string | null>(null)
  const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null)
  const [selectedTableId, setSelectedTableId] = useState<number | null>(null)
  
  const partsNumber = useMemo(() => Math.floor(60 / period), [period])
  
  
  const MINUTE_MS = 60000

  
  useEffect(() => {
    const interval = setInterval(() => {
      setNowTime(dayjs(dayjs().format('YYYY-MM-DD HH:mm')).unix())
    }, MINUTE_MS);
    return () => clearInterval(interval)
  }, [])

  const formatNowTime = useMemo(() => (
    dayjs(nowTime * 1000).format('HH:mm')
  ), [nowTime])

  const handleClickCreateRecord = (tableId: number, datetime: string) => {
    const resultDatetime = dayjs(datetime).toDate()
    setSelectedTableId(tableId)
    setSelectedDateTime(resultDatetime)
    setIsCreateModalOpen(true)
  }

  const handleClickEditRecord = (recordId: number | null) => {
    if (recordId === null) {
      return
    }
    setSelectedRecordId(recordId)
    setIsEditModalOpen(true)
  }

  useEffect(() => {
    setSelectedDate(date || null)
  }, [date])
  
  useEffect(() => {
    if (selectedDate === null || isCreateModalOpen || isEditModalOpen) {
      return
    }
    dp.getList(`rooms/${roomId}/tables`, {
      pagination: {
        page: 1,
        perPage: 10
      },
      filter: {},
      sort: {
        field: 'id',
        order: 'asc'
      }
    }).then((result: { data?: ApiTable[] }) => {
      const results = (result?.data || []).filter((item) => (
        item?.status
      )) || []
      setTables(results)
    })
  }, [selectedDate, selectedTableId, isCreateModalOpen, isEditModalOpen])

  useEffect(() => {
    if (selectedDate === null || isCreateModalOpen || isEditModalOpen) {
      return
    }
    setTimeout(() => {
      dp.getList(`rooms/${roomId}/records`, {
        pagination: {
          page: 1,
          perPage: 10
        },
        filter: {
          startDate: dayjs(date).subtract(1, 'day').toISOString(),
          endDate: dayjs(date).toISOString(),
        },
        sort: {
          field: 'id',
          order: 'desc'
        }
      }).then((result: { data?: ApiRecord[] }) => {
        const results = (result?.data ?? []).filter((item) => (
          !item?.isDeleted
        )) || []
        setRecords(results as Record[])
      })
    }, 1000)
  }, [selectedDate, isCreateModalOpen, isEditModalOpen])


  return (
    <>
      <Form>
        <SelectInput
          label="Интервал"
          emptyText={'5 мин'}
          emptyValue={5}
          defaultValue={30}
          source="interval"
          onChange={({ target }) => setPeriod(target.value)}
          choices={[
            { id: 10, name: '10 мин' },
            { id: 15, name: '15 мин' },
            { id: 20, name: '20 мин' },
            { id: 30, name: '30 мин' },
            { id: 60, name: '60 мин' },
          ]}
        />
        <SelectInput
          label="Высота ячейки"
          emptyText={'8px'}
          emptyValue={8}
          defaultValue={16}
          source="height"
          onChange={({ target }) => setCellHeight(target.value)}
          choices={Array.from(Array(15).keys()).map((item) => (
            { id: 10 + item * 2, name: `${10 + item * 2}px` }
          ))}
        />
        <SelectInput
          label="Смещение времени"
          emptyText={'0ч.'}
          emptyValue={0}
          defaultValue={0}
          source="offset"
          onChange={({ target }) => setOffsetHours(target.value)}
          choices={Array.from(Array(11).keys()).map((item) => (
            { id: 1 + item, name: `${1 + item}ч.` }
          ))}
        />
      </Form>

      <RecordCreateModal
        roomId={roomId}
        isOpen={isCreateModalOpen}
        setIsOpen={setIsCreateModalOpen}
        date={selectedDateTime}
        tableId={selectedTableId}
      />

      <RecordEditModal
        roomId={roomId}
        recordId={selectedRecordId}
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
      />

      <h2>Записи на {date}!</h2>
      <CalendarContainer>
        <CalendarTableListWrapper>
          {tables.map((table) => {
            const tableTypeName = [
              { id: 'pyramid', name: 'Русская пирамида' },
              { id: 'pool', name: 'Американский пул' },
              { id: 'snooker', name: 'Снукер' },
              { id: 'carom', name: 'Карамболь' },
            ].find(item => item.id === table.type)?.name || ''
            return (
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <div>{table.name}</div>
                <div style={{
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#222222'
                }}>{tableTypeName}</div>
              </div>
            )
          })}
        </CalendarTableListWrapper>
        <CalendarContentWrapper>
          <TimeLabelList>
            {Array.from(Array(24).keys()).map((item) => {
              const hour = dayjs(`${date} ${item}`).add(offsetHours, 'hour').hour()
              const formatHour = `0${hour}`.slice(-2)
              return (
                <div>{formatHour}</div>
              )
            })}
          </TimeLabelList>
          {Array.from(Array(24 * 60).keys()).map((item, index) => {
            const cellTime = dayjs(`${date}`).unix() + (index * 60) +  offsetHours * 3600 
            const isNowTimeCell = cellTime === nowTime
            
            return isNowTimeCell && (
              <NowTimeLineWrapper partNumber={index}>
                <NowTimeLine time={formatNowTime} />
              </NowTimeLineWrapper>
            )
          })}
          {tables.map((table) => {
            return (
              <RecordList>
                {Array.from(Array(24).keys()).map((item, hourIndex) => {
                  return (
                    <RecordHourRow key={`hour-${hourIndex}`} height={cellHeight * partsNumber}>
                      {Array.from(Array(partsNumber).keys()).map((item, index) => {
                        const isBetween = index !== 0
                        const cellDateTime = dayjs(`${date} ${hourIndex}:${index * period}`).add(offsetHours, 'hour').format('YYYY-MM-DD HH:mm')
                        const cellTime = dayjs(`${date} ${hourIndex}:${index * period}`).add(offsetHours, 'hour').format('HH:mm')
                        
                        return (
                          <>
                            <RecordCell
                              isRecorded={false}
                              isBetween={isBetween}
                              height={cellHeight}
                              onClick={() => handleClickCreateRecord(table.id, cellDateTime)}
                            >
                              <RecordCellLabel height={cellHeight}>
                                {cellTime}
                              </RecordCellLabel>
                            </RecordCell>
                          </>
                        )
                      })}
                      {Array.from(Array(60).keys()).map((item, index) => {
                        const cellTime = dayjs(`${date} ${hourIndex}:${index}`).add(offsetHours, 'hour').unix()
                        
                        const cellRecords = records.filter(record => record.table.id === table.id).map((item) => {
                          const startTime = dayjs(item.datetime).unix()
                          const endTime = dayjs(item.datetime).unix() + (item.duration * 60)
                          const toChangeDayTime = dayjs(`${date} 00:00`).add(24 + offsetHours, 'hour').unix() - cellTime
                          
                          if (cellTime >= startTime && endTime > cellTime) {
                            item.isStart = cellTime === startTime
                            item.leftDuration = Math.min(item.duration, toChangeDayTime / 60)
                            item.startTime = startTime
                            item.endTime = endTime
                            item.startFormatTime = dayjs(item.datetime).format('HH:mm')
                            item.endFormatTime = dayjs(item.datetime).add(item.duration, 'minute').format('HH:mm')
                            item.isOutOfRange = false
                            return item
                          }
                          item.isOutOfRange = true
                          return item
                        }).filter(item => !item.isOutOfRange)

                        const isRecorded = !!cellRecords?.length
                        let startCellRecord = cellRecords.find(item => item.isStart)

                        if (!startCellRecord && hourIndex === 0 && index === 0 && isRecorded) {
                          startCellRecord = cellRecords[0]
                          startCellRecord.leftDuration = Math.round((startCellRecord.endTime - dayjs(date).unix()) / 60) - offsetHours * 60
                        }

                        
                        const deviceScripts = startCellRecord?.deviceScripts
                        const groupedDeviceScripts = _.groupBy(
                          deviceScripts,
                          deviceScript => deviceScript.abstractDevice.name
                        )
                        
                        return (
                          <>
                            {startCellRecord && (
                            <RecordBlockWrapper
                              duration={startCellRecord.leftDuration}
                              partNumber={index}
                              onClick={() => handleClickEditRecord(startCellRecord?.id || null)}
                            >
                              <RecordBlock>
                                <RecordInfoWrapper>
                                  <div>{startCellRecord.startFormatTime} - {startCellRecord.endFormatTime}</div>
                                  <div>{startCellRecord?.service?.name || ''}</div>
                                  <div>{startCellRecord?.customer?.firstName || ''}</div>
                                  <div>{startCellRecord?.customer?.phone || ''}</div>
                                </RecordInfoWrapper>
                                <DevicesWrapper>
                                  {Object.keys(groupedDeviceScripts)?.map(key =>
                                    <DeviceGroupWrapper>
                                      {groupedDeviceScripts[key].map(item => {
                                        const position = item.timeOffsetType === 'FROM_START' ? 'top' : 'bottom'
                                        return (
                                          <DeviceIconWrapper
                                            position={position}
                                            duration={startCellRecord?.leftDuration || 0}
                                            offset={item?.timeOffset}
                                          >
                                            {item?.command === 'on' ? <HangingLampOnSvg /> : <HangingLampOffSvg />}
                                          </DeviceIconWrapper>
                                        )
                                      })}
                                    </DeviceGroupWrapper>
                                  )}
                                </DevicesWrapper>
                              </RecordBlock>
                            </RecordBlockWrapper>
                            )}
                          </>
                        )
                      })}
                    </RecordHourRow>
                  )
                })}
              </RecordList>
            )
          })}
        </CalendarContentWrapper>
      </CalendarContainer>
    </>
  )
}