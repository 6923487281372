import {
  Datagrid,
  BooleanField,
  List,
  TextField,
  FunctionField,
  ImageField,
  ReferenceArrayField,
  ReferenceField,
  DateField,
  SearchInput,
  DateInput,
  BooleanInput,
  NumberField,
  ReferenceInput,
  EmailField,
  useListContext,
  useRecordContext,
  Identifier,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useRefresh,
} from "react-admin"
import { durationFormat } from "../../utils/time-helpers"
import { Record } from "../../types/common"
import EditIcon from '@mui/icons-material/Edit'
import { useEffect, useState } from "react"
import { RecordEditModal } from "../calendar/recordEditModal"

const recordFilters = [
  <SearchInput source="q" alwaysOn />,
  <DateInput label="datetime" source="datetime" defaultValue={new Date()} />,
  <ReferenceInput source="service" reference="services" label="Услуга">
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <BooleanInput label="Статус" source="status" defaultValue={true} />,
]

const recordRowStyle = (record: Record, index: number) => ({
  background: record?.isDeleted ? '#FFCCCC' : 'transparent',
})


const DatagridCustom = ({ onEdit }: { onEdit: Function}) => {
  const EditButton = () => {
    const record = useRecordContext()
    return (
      <button
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '4px'
        }}
        onClick={() => onEdit(record.id)}
      >
        <EditIcon style={{ height: '16px'}} />
      </button>
    )
  }


  return (
    <Datagrid rowStyle={recordRowStyle}>
      <TextField source="id" label="ID" />
      <ReferenceField source="service.id" reference="services" label="Услуга">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="customer.id" reference="customers" label="Посетитель">
        <TextField source="firstName" /> (<TextField source="phone" />)
      </ReferenceField>
      <DateField source="datetime" label="Время" showTime />
      <FunctionField
        label="Длительность"
        render={(record: Record) => durationFormat(record.duration)}
      />
      <TextField source="ycRecordId" label="Yclients ID" />
      <EditButton />
    </Datagrid>
  )
}

export const RecordList = ({ roomId }: { roomId: number}) => {
  const refresh = useRefresh()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null)
  
  useEffect(() => {
    if (!isEditModalOpen) {
      refresh()
    }
  }, [isEditModalOpen])
  
  const onEdit = (recordId: number) => {
    setSelectedRecordId(recordId)
    setIsEditModalOpen(true)
  }

  return (
    <>
      <RecordEditModal
        roomId={roomId}
        recordId={selectedRecordId}
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        />
      <List hasCreate={false} hasEdit={false} filters={recordFilters}>
        <DatagridCustom onEdit={onEdit} />
      </List>
    </>
  )
}