import React, { useEffect, useRef, useState } from 'react'
import {FormProvider, useFormContext, useForm, useWatch} from 'react-hook-form'

import {
  Create,
  Edit,
  Form,
  SimpleForm,
  TextInput,
  DateTimeInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  AutocompleteInput,
  required,
  useRecordContext,
  useDataProvider,
  useRecordSelection,
  Identifier,
  RaRecord,
  useGetOne,
  useGetList,
  useInput,
  BooleanInput,
} from 'react-admin'
import { getDurationParts } from '../../utils/time-helpers'
import { DurationInput } from '../../components/DurationInput/input'


  
export const RecordCreateForm = ({
  roomId,
  tableId,
  date,
  onSubmit,
}: {
  roomId: number
  tableId: number | null
  date: Date | null
  onSubmit: Function
}) => {
  
  const dp = useDataProvider()
  
  const handleSubmit = (values: any) => {
    dp.create(`rooms/${roomId}/records`, { data: values })
    .then(({ data }) => {
      onSubmit()
    })
    .catch(error => {
      //showNotification(error.message, "error");
    })
    .finally(() => {
      // Dispatch an action letting react-admin know a API call has ended
      //fetchEnd();
    });
    
  };
  

  const RecordForm = () => {
    const { getValues, setValue } = useFormContext()
    const [duration, setDuration] = useState(0)

    useEffect(() => {
      setValue('duration', duration)
    }, [duration])

    const TableSelect = ({ tableId }: { tableId: number | null}) => {
      const handleSelectTable = (e: RaRecord<Identifier> | React.ChangeEvent<HTMLInputElement>) => {
        
      }
      return (
        <ReferenceInput source="table.id" reference="tables" isRequired>
          <SelectInput
            label="Стол"
            optionText={'name'}
            defaultValue={tableId}
            onChange={handleSelectTable}
            isRequired
          />
        </ReferenceInput>
      )
    }

    const ServiceSelect = () => {
      
      const handleSelectService = (e: RaRecord<Identifier> | React.ChangeEvent<HTMLInputElement>) => {
        dp.getOne('services', { id: e.target.value }).then(({ data }: any) => {
          setDuration(data?.duration)
        })
      }


      return (
        <ReferenceInput perPage={100} source="service.id" reference="services" isRequired>
          <SelectInput
            label="Услуга"
            optionText={'name'}
            onChange={handleSelectService}
            isRequired
          />
        </ReferenceInput>
      )
    }

    const CustomerSelect = () => {
      
      const handleSelectCustomer = (e: RaRecord<Identifier> | React.ChangeEvent<HTMLInputElement>) => {
        
      }
  
      return (
        <ReferenceInput source="customer.id" reference="customers" isRequired>
          <AutocompleteInput
            label="Посетитель"
            optionText={'firstName'}
            onChange={handleSelectCustomer}
            isRequired
            validate={required()}
          />
        </ReferenceInput>
      )
    }


    return (
      <>
        <TableSelect tableId={tableId} />
        <ServiceSelect />
        <CustomerSelect />
        <DateTimeInput
          source="datetime"
          label="Время"
          defaultValue={date}
          isRequired
          validate={required()}
        />
        <TextInput
          source="duration"
          label="Продолжительность"
          isRequired
          validate={required()}
          style={{display:'none'}}
        />
        <DurationInput duration={duration} />
        <BooleanInput source="status" />
      </>
    )
  }

  
  return (
      <SimpleForm onSubmit={handleSubmit} mode="onBlur" reValidateMode="onBlur">
        <RecordForm />
      </SimpleForm>
  )
}