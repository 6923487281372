import React, { useEffect, useRef, useState } from 'react'
import {FormProvider, useFormContext, useForm, useWatch} from 'react-hook-form'

import {
  Create,
  Edit,
  Form,
  SimpleForm,
  TextInput,
  DateTimeInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  AutocompleteInput,
  required,
  useRecordContext,
  useDataProvider,
  useRecordSelection,
  Identifier,
  RaRecord,
  useGetOne,
  useGetList,
  useInput,
  BooleanInput,
} from 'react-admin'


  
export const RecordCreate = (props: any) => {
  
  const dp = useDataProvider()
  

  const RecordForm = () => {
    const { setValue } = useFormContext()

    const SelectService = () => {
    
      const handleSelectService = (e: RaRecord<Identifier> | React.ChangeEvent<HTMLInputElement>) => {
        dp.getOne('services', { id: e.target.value }).then(({ data }: any) => {
          setValue('duration', data?.duration)
        })
      }
  
      return (
        <ReferenceInput source="service.id" reference="services">
          <SelectInput
            label="Услуга"
            optionText={'name'}
            onChange={handleSelectService}
          />
        </ReferenceInput>
      )
    }


    return (
      <>
        <SelectService />
        <DateTimeInput source="datetime" />
        <TextInput
          source="duration"
        />
        <BooleanInput source="status" />
      </>
    )
  }

  
  return (
    <Create>
      <SimpleForm>
        <RecordForm />
      </SimpleForm>
    </Create>
  )
}