import { useFormContext } from 'react-hook-form'

import {
  Edit,
  Form,
  SimpleForm,
  TextInput,
  DateTimeInput,
  BooleanInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  AutocompleteInput,
  required,
} from 'react-admin'

import { DurationInput } from '../../components/DurationInput/input'

  
export const ServiceEdit = (props: any) => {

  return (
    <Edit>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput
          source="duration"
          style={{display:'none'}}
        />
        <DurationInput />
        <ReferenceInput source="serviceCategory.id" reference="serviceCategories">
          <SelectInput
            label="Категория"
            optionText={'name'}
          />
        </ReferenceInput>
        <BooleanInput source="status" />
      </SimpleForm>
    </Edit>
  )
}
