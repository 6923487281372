import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

import { RecordEditForm } from './recordEditForm'


export const RecordEditModal = ({
  roomId,
  recordId,
  isOpen,
  setIsOpen
}: {
  roomId: number
  recordId: number | null
  isOpen: boolean,
  setIsOpen: Function
}) => {

  const handleSubmit = () => {
    setIsOpen(false)
  }
  
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Редактировать запись</DialogTitle>
        <DialogContent>
          <RecordEditForm roomId={roomId} recordId={recordId} onSubmit={handleSubmit} />
        </DialogContent>
      </Dialog>
    </>
  )
}