import { useFormContext } from 'react-hook-form'

import {
  Edit,
  Form,
  SimpleForm,
  TextInput,
  DateTimeInput,
  BooleanInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  AutocompleteInput,
  required,
} from 'react-admin'

import { getDurationParts } from '../../utils/time-helpers'

  
export const DurationInput = ({ duration }: { duration?: number }) => {
  const { getValues, setValue } = useFormContext()

	if (!duration) {
		duration = getValues('duration')
	}
  const [hoursDuration, minutesDuration] = getDurationParts(duration || 0)
  setValue('hoursDuration', hoursDuration)
  setValue('minutesDuration', minutesDuration)

  const handleSelectHoursDuration = (event: any) => {
    const minutes = getValues('minutesDuration')
    const duration = event.target.value * 60 + minutes
    setValue('duration', duration)
  }

  const handleSelectMinutesDuration = (event: any) => {
    const hours = getValues('hoursDuration')
    const duration = hours * 60 + event.target.value
    setValue('duration', duration)
  }

  const hoursChoices = [...Array(24)].map((_, i) => ({
    id: i + 1, name: `${i + 1} ч`
  }))

  const minutesChoices = [...Array(12)].map((_, i) => ({
    id: (i + 1) * 5, name: `${(i + 1) * 5} мин`
  }))


  return (
    <>
			<SelectInput
				emptyValue={0}
				emptyText={'0 ч'}
				source="hoursDuration"
				label="Часы"
				choices={hoursChoices}
				onChange={handleSelectHoursDuration}
			/>
			<SelectInput
				emptyValue={0}
				emptyText={'0 мин'}
				source="minutesDuration"
				label="Минуты"
				choices={minutesChoices}
				onChange={handleSelectMinutesDuration}
			/>
    </>
  )
}
