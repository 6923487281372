import { styled } from "styled-components"

import { ReactComponent as NextSvg } from '../../assets/components/DateSwitcher/svg/arrow-right.svg'


export const CalendarContainer = styled.div`
  padding: 8px;
`

export const CalendarControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
`

export const ControlButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  cursor: pointer;
`

export const PrevMonthButton = styled(NextSvg)`
  transform: rotate(180deg);
`

export const NextMonthButton = styled(NextSvg)`
  
`

export const CurrentInfoWrapper = styled.div`
  font-weight: 600;
`

export const CalendarWeekDays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding-bottom: 8px;
  margin-top: 8px;

  & > span {
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 24px;
  }
`

export const CalendarGridItems = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`

type DayGridItemPropsType = {
  isToday: boolean
  isSelected: boolean
  isSelectedMonth: boolean
}

export const DayGridItem = styled.div<DayGridItemPropsType>`
  display: flex;
  justify-content: center;
  font-size: ${props => props.isSelectedMonth ? '14px' : '11px'};
  line-height: 28px;
  background: ${props => props.isSelected ? '#eeee00' : 'transparent'};
  border-radius: 5px;
  color: ${props => props.isToday ? 'red' : props.isSelectedMonth ? '#333333' : '#888888'};
  font-weight: ${props => (props.isToday || props.isSelected) ? '600' : '500'};
  cursor: pointer;
  

  &:hover {
    background: ${props => props.isSelected ? '#eeee00' : '#999999'};
    color: ${props => props.isSelected ? props.isToday ? 'red' : 'gray' : '#ffffff'};
    
    font-size: 17px;
    
  }
`
