import {
  Datagrid,
  BooleanField,
  List,
  TextField,
  FunctionField,
  ImageField,
  ReferenceArrayField,
  ReferenceField,
  DateField,
  SearchInput,
  DateInput,
  BooleanInput,
  NumberField,
  ReferenceInput,
  EmailField,
  useListContext,
  useRecordContext,
  Identifier,
} from "react-admin"

const roomFilters = [
  <SearchInput source="q" alwaysOn />,
  <DateInput label="Published date" source="published_date" defaultValue={new Date()} />,
  <ReferenceInput source="author" reference="authors" label="Author" />,
  <BooleanInput label="Featured" source="is_featured" defaultValue={true} />,
]

export const RoomList = ({ onChange }: { onChange: Function}) => {
  
  const handleSelectRoom = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, room: any) => {
    e.preventDefault()
    e.stopPropagation()
    localStorage.setItem('selectedRoom', JSON.stringify(room))
    onChange(room)
  }

  const SelectRoom = () => {
    const room = useRecordContext()
    
    if (!room) return null
    
    return (
      <div
        onClick={(e) => handleSelectRoom(e, room)}>
        Выбрать
      </div>
    )
  }


  return (
    <List hasCreate hasEdit filters={roomFilters}>
      <Datagrid rowClick="edit">
        <TextField source="name" label="Название" />
        <BooleanField source="status" label="Состояние" />
        <SelectRoom />
      </Datagrid>
    </List>
  )
}