import { AppBar as CoreAppBar } from 'react-admin';
import { Typography } from '@mui/material';

export const AppBar = ({ room }: any) => {
  
  return (
    <CoreAppBar
      color="primary"
    >
      <Typography variant="h6" flex="1">{room?.name}</Typography>
    </CoreAppBar>
  )
}
