import {
  Datagrid,
  BooleanField,
  List,
  TextField,
  FunctionField,
  ImageField,
  ReferenceArrayField,
  ReferenceField,
  DateField,
  SearchInput,
  DateInput,
  BooleanInput,
  NumberField,
  ReferenceInput,
  EmailField,
  useListContext,
  useRecordContext,
  Identifier,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  TextInput,
} from "react-admin"

const serviceFilters = [
  <SearchInput source="q" alwaysOn />,
  <TextInput label="Имя" source="firstName" />,
  <TextInput
    label="Телефон"
    source="phone"
    format={item => decodeURIComponent(item)}
    parse={item => encodeURIComponent(item)}
  />,
  <TextInput label="Email" source="email" />,
  <BooleanInput label="Статус" source="status" defaultValue={true} />,
]

export const CustomerList = () => {

  return (
    <List hasCreate hasEdit filters={serviceFilters}>
      <Datagrid rowClick="edit">
        <TextField source="firstName" label="Имя" />
        <TextField source="phone" label="Телефон" />
        <TextField source="email" label="Email" />
      </Datagrid>
    </List>
  )
}