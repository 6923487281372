import {
  Create,
  Edit,
  Form,
  SimpleForm,
  TextInput,
  DateTimeInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  AutocompleteInput,
  required,
  BooleanInput,
} from 'react-admin'


  
export const CustomerCreate = (props: any) => (
  <Create>
    <SimpleForm>
      <TextInput label="Имя" source="firstName" />
      <TextInput label="Телефон" source="phone" />
      <TextInput label="Email" source="email" />
      <BooleanInput label="Статус" source="status" />
    </SimpleForm>
  </Create>
)
