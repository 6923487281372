import {
  Edit,
  Form,
  SimpleForm,
  TextInput,
  DateTimeInput,
  BooleanInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  AutocompleteInput,
  required,
} from 'react-admin'


  
export const RoomEdit = (props: any) => (
  <Edit>
    <SimpleForm>
      <TextInput source="name" />
      <BooleanInput source="status" />
    </SimpleForm>
  </Edit>
)
