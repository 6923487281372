import { fetchUtils } from 'react-admin'

const strapiApiUrl = process.env.REACT_APP_API_URL || 'http://localhost:1337/api'

export default {
	login: async ({ username, password }: { username: string, password: string }) => {
		const httpClient = fetchUtils.fetchJson
		const options = {}
		const query = `login=${username}&password=${password}`
		const result = await httpClient(`${strapiApiUrl}/auth?${query}`, options)
		if (result?.json?.token) {
			localStorage.setItem('token', result.json.token)
			return Promise.resolve()
		}
		return Promise.reject()
	},
	// called when the user clicks on the logout button
	logout: () => {
		localStorage.removeItem('token')
		return Promise.resolve()
	},
	// called when the API returns an error
	checkError: ({ status }: { status: number }) => {
		if ((status === 401 || status === 403) && false) {
				localStorage.removeItem('token')
				return Promise.reject()
		}
		return Promise.resolve()
	},
	// called when the user navigates to a new location, to check for authentication
	checkAuth: () => {
		return localStorage.getItem('token')
			? Promise.resolve()
			: Promise.reject()
	},
	// called when the user navigates to a new location, to check for permissions / roles
	getPermissions: () => Promise.resolve(),
}
