import { css, styled } from "styled-components"


export const CalendarContainer = styled.div`
  height: 100%;
`

export const CalendarTableListWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  column-gap: 6px;
  height: auto;
  padding-left: 25px;

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

export const CalendarContentWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  column-gap: 5px;
  margin: 16px 0 60px;
  height: auto;
`

export const TimeLabelList = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 22px;

  & > div {
    font-family: 'courier';
    height: 100%;
    line-height: 100%;
    margin-top: -50%;
    text-align: right;
  }
`

export const RecordList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

type RecordHourRowPropsType = {
  height: number
}

export const RecordHourRow = styled.div<RecordHourRowPropsType>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${props => props.height}px;
  background: white;
`

type RecordCellPropsType = {
  isRecorded: boolean
  isBetween: boolean
  height: number
}

export const RecordCell = styled.div<RecordCellPropsType>`
  position: relative;
  width: 100%;
  height: ${props => props.height}px;
  //border-top: 1px solid #555555;
  //background: ${props => props.isRecorded ? 'rgba(100, 100, 100, 0.5)': 'transparent'};
  pointer-events: ${props => props.isRecorded ? 'none': 'auto'};

  &:hover {
    background: #22aa22;
    border-radius: 6px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    border-top: ${props => props.isBetween ? '1px solid #dddddd': '1px solid #aaaaaa'};
  }
`

export const RecordCellLabel = styled.div<{ height: number }>`
  position: absolute;
  width: 100%;
  padding-left: 4px;
  color: #ffffff;
  font-size: ${props => props.height - 2}px;
  line-height: ${props => props.height}px;
  user-select: none;
`

type RecordBlockPropsType = {
  duration: number
  partNumber: number
}

export const RecordBlockWrapper = styled.div<RecordBlockPropsType>`
  position: absolute;
  top: calc(100% / 60 * ${props => props.partNumber});
  background: transparent;
  width: 100%;
  height: calc(100% * ${props => props.duration} / 60 + 1px);
  pointer-events: fill;
  z-index: 10;
`

export const RecordBlock = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  background: rgba(150, 255, 150, 0.8);
  border-radius: 6px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 16px;
`

export const RecordInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 6px;
  flex-wrap: wrap;
  width: fit-content;
  height: 100%;
  font-size: 14px;
  line-height: 16px;
  padding: 0px 4px;
`

export const DevicesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DeviceGroupWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 32px;
`

type DeviceIconWrapperPropsType = {
  position: 'top' | 'bottom'
  offset: number
  duration: number
}

export const DeviceIconWrapper = styled.div<DeviceIconWrapperPropsType>`
  position: absolute;
  ${props => props.position === 'top' && css`
    top: calc(${100 / props.duration * props.offset}%);
  `}
  ${props => props.position === 'bottom' && css`
    bottom: calc(${100 / props.duration * -props.offset}%);
  `}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;

  & > svg {
    width: 24px;
    height: 24px;
  }
`

type NowTimeLinePropsType = {
  partNumber: number
}

export const NowTimeLineWrapper = styled.div<NowTimeLinePropsType>`
  position: absolute;
  top: calc(100% / ( 60 * 24 ) * ${props => props.partNumber});
  background: transparent;
  width: 100%;
  height: 1px;
  z-index: 10;
  pointer-events: none;
`

export const NowTimeLine = styled.div<{ time: string }>`
  position: absolute;
  background: rgba(255, 100, 100, 0.8);
  border-radius: 2px;
  width: 100%;
  height: 2px;

  &::before {
    content: '${props => props.time}';
    position: absolute;
    top: -10px;
    left: -40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100%;
    background: #444444;
    color: #ffffff;
    border-radius: 4px;
    padding: 2px 4px;
  }
`
