import { Admin, Resource, defaultTheme } from 'react-admin'

export const theme = {
  ...defaultTheme,
  typography: {
    fontFamily: ['Kardinal Pro'].join(','),
    
  },
  components: {
    ...defaultTheme.components,
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '1.050rem',
          lineHeight: '1.4'
        },
        h6: {
          fontWeight: 600,
        }
      }
    },
    MuiMenuItem : {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: '1.150rem',
          lineHeight: '1.4',
          '&.RaMenuItemLink-active': {
            fontWeight: 600,
            color: '#333 !important'
          }
        },
      }
    },
  },
}
