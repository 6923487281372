import { Layout as CoreLayout} from 'react-admin'

import { AppBar } from './AppBar'

export const Layout = (props: any) => {
  return (
    <CoreLayout
      {...props}
      appBar={() => AppBar(props)}
    />
  )
}