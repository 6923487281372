import { styled } from "styled-components"


export const DevicesContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  column-gap: 5px;
  margin: 20px 0 60px;
  height: auto;
`
