import {
  Create,
  Edit,
  Form,
  SimpleForm,
  TextInput,
  DateTimeInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  AutocompleteInput,
  required,
  BooleanInput,
} from 'react-admin'


  
export const TableCreate = (props: any) => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <SelectInput source="type" choices={[
          { id: 'pyramid', name: 'Русская пирамида' },
          { id: 'pool', name: 'Американский пул' },
          { id: 'snooker', name: 'Снукер' },
          { id: 'carom', name: 'Карамболь' },
      ]} />
      <BooleanInput source="status" />
    </SimpleForm>
  </Create>
)
