import {
  Create,
  Edit,
  Form,
  SimpleForm,
  TextInput,
  DateTimeInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  AutocompleteInput,
  required,
  BooleanInput,
} from 'react-admin'
import { DurationInput } from '../../components/DurationInput/input'


  
export const ServiceCreate = (props: any) => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput
          source="duration"
          style={{display:'none'}}
        />
      <DurationInput />
      <ReferenceInput source="serviceCategory.id" reference="serviceCategories">
        <SelectInput
          label="Категория"
          optionText={'name'}
        />
      </ReferenceInput>
      <BooleanInput source="status" />
    </SimpleForm>
  </Create>
)
