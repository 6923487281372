import { useEffect, useMemo, useState } from 'react'
import { useDataProvider, SelectInput, SelectField, Form } from 'react-admin'
import { useParams } from 'react-router'

import {
  DevicesContainer,
} from './styles'
import dayjs from 'dayjs'

import { ApiDevice } from '../../types/common'

const log = console.log


export const Devices = ({ roomId }: { roomId: number }) => {
  const dp = useDataProvider()
  const { date } = useParams()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [devices, setDevices] = useState<ApiDevice[]>([])
  const [period, setPeriod] = useState<number>(30)
  const [nowTime, setNowTime] = useState<number>(dayjs(dayjs().format('YYYY-MM-DD HH:mm')).unix())
  
  const partsNumber = useMemo(() => Math.floor(60 / period), [period])

  const MINUTE_MS = 60000
  
  useEffect(() => {
    const interval = setInterval(() => {
      console.log('Logs every minute');
      setNowTime(dayjs(dayjs().format('YYYY-MM-DD HH:mm')).unix())
    }, MINUTE_MS);
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    dp.getList(`rooms/${roomId}/devices`, {
      pagination: {
        page: 1,
        perPage: 10
      },
      filter: {},
      sort: {
        field: 'id',
        order: 'desc'
      }
    }).then((result: { data?: ApiDevice[] }) => {
      const results = (result?.data || []).filter((item) => (
        item?.status
      )) || []
      setDevices(results as ApiDevice[])
    })
  }, [])


  return (
    <DevicesContainer>
      Устройства:
      {devices.map(item => {
        const status = item.tuyaStatus?.find(item => (
          ['switch', 'switch_1'].includes(item.code)
        ))?.value || false

        return (
          <div
            key={item.deviceId}
            style={{ color: status ? 'green' : 'red' }}
          >
            {item.name}
          </div>
        )
      })}
    </DevicesContainer>
  )
}