import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { DashboardMenuItem, Menu as RaMenu, Resource } from 'react-admin'
import { MenuItemLink } from './MenuItemLink'
import BookIcon from '@mui/icons-material/Book'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import PeopleIcon from '@mui/icons-material/People'
import LabelIcon from '@mui/icons-material/Label'



import { RecordList } from '../pages/records/recordList'
import { RecordEdit } from '../pages/records/recordEdit'
import { RecordCreate } from '../pages/records/recordCreate'


import {
  Storefront,
  ViewList,
  Room,
  RoomService,
  RoomSharp,
  Store,
  EventNote,
  Receipt,
  Shop,
  DeviceHub,
} from '@material-ui/icons'
import DateSwitcher from '../components/DateSwitcher'

import { DateSwitcherWrapper } from './styles'



export const Menu = (props: any) => {
  const [room, setRoom] = React.useState(JSON.parse(localStorage.getItem('selectedRoom') || '{}'))
  
  let navigate = useNavigate();
  
  const routeChange = (date: Date) =>{
    let path = `calendar/${dayjs(date).format('YYYY-MM-DD')}`
    navigate(path)
  }

  const links = [
    {
      text: 'Записи',
      path: `/rooms/${room?.id || 0}/records`,
      icon: <EventNote />,
      sortOrder: 10,
    },
    {
      text: 'Комнаты',
      path: `/rooms`,
      icon: <Store />,
      sortOrder: 20,
    },
    {
      text: 'Столы',
      path: `/rooms/${room?.id || 0}/tables`,
      icon: <Store />,
      sortOrder: 30,
    },
    {
      text: 'Категории услуг',
      path: `/serviceCategories`,
      icon: <Shop />,
      sortOrder: 40,
    },
    {
      text: 'Услуги',
      path: `/services`,
      icon: <Shop />,
      sortOrder: 50,
    },
    {
      text: 'Посетители',
      path: `/customers`,
      icon: <Shop />,
      sortOrder: 60,
    },
  ]

  links.sort((a, b) => a.sortOrder - b.sortOrder)
  

  return (
    <>
      <DateSwitcherWrapper>
        <DateSwitcher onChange={routeChange} />
      </DateSwitcherWrapper>
      <RaMenu {...props}>
        {links.map(({ path, text, icon}) => (
          <MenuItemLink to={path} primaryText={text} leftIcon={icon} />
        ))}
      </RaMenu>
      <MenuItemLink to={'/devices'} primaryText={'Устройства'} leftIcon={<DeviceHub />} />
    </>
  )
}
