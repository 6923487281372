import { useState } from 'react'
import { Route } from 'react-router-dom'
import { Admin, CustomRoutes, Resource } from 'react-admin'
import { Layout as RaLayout } from './Layout'
import { dataProvider } from './dataProvider'
import authProvider from './authProvider'
import { Menu } from './menu'
import { Calendar } from './pages/calendar'
import { Devices } from './pages/devices'
import { RoomList } from './pages/rooms/roomList'
import { RecordList } from './pages/records/recordList'
import { RecordEdit } from './pages/records/recordEdit'
import { RecordCreate } from './pages/records/recordCreate'
import { SubscriberList } from './pages/subscribers/subscriberList'
import { RoomEdit } from './pages/rooms/roomEdit'
import { RoomCreate } from './pages/rooms/roomCreate'
import { ServiceList } from './pages/services/serviceList'
import { ServiceEdit } from './pages/services/serviceEdit'
import { ServiceCreate } from './pages/services/serviceCreate'
import { TableList } from './pages/tables/tableList'
import { TableEdit } from './pages/tables/tableEdit'
import { TableCreate } from './pages/tables/tableCreate'
import { CustomerList } from './pages/customers/customerList'
import { CustomerEdit } from './pages/customers/customerEdit'
import { CustomerCreate } from './pages/customers/customerCreate'


import { theme } from './theme'

import {
  Storefront,
  ViewList,
  Room,
  RoomService,
  RoomSharp,
  Store,
  EventNote,
  Receipt,
  Shop
} from '@material-ui/icons'
import { ServiceCategoryList } from './pages/serviceCategories/serviceCategoryList'
import { ServiceCategoryEdit } from './pages/serviceCategories/serviceCategoryEdit'
import { ServiceCategoryCreate } from './pages/serviceCategories/serviceCategoryCreate'


export const Layout = (props: any) => <RaLayout {...props} menu={Menu} />;

export const App = () => {
  const [room, setRoom] = useState(JSON.parse(localStorage.getItem('selectedRoom') || '{}'))

  const RoomListMiddleware = () => {
    return <RoomList onChange={setRoom} />
  }

  return (
    <Admin
      layout={(props) => Layout({ ...props, room })}
      title={`Панель управления (${room?.name || ''})`}
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={theme}
    >
      <Resource
        icon={EventNote}
        name={`rooms/${room?.id || 0}/records`}
        list={() => RecordList({ roomId: room.id})}
        edit={RecordEdit}
        create={RecordCreate}
        options={{ label: "Записи", name: "records" }}
      />
      <Resource
        icon={Store}
        name="rooms"
        list={RoomListMiddleware}
        edit={RoomEdit}
        create={RoomCreate}
        options={{ label: "Комнаты", name: "rooms" }}
      />
      <Resource
        icon={Shop}
        name={`rooms/${room?.id || 0}/tables`}
        list={TableList}
        edit={TableEdit}
        create={TableCreate}
        options={{ label: "Столы", name: "tables" }}
      />
      <Resource
        icon={Shop}
        name={`serviceCategories`}
        list={ServiceCategoryList}
        edit={ServiceCategoryEdit}
        create={ServiceCategoryCreate}
        options={{ label: "Категории услуг", isMenu: false }}
      />
      <Resource
        icon={Shop}
        name={`services`}
        list={ServiceList}
        edit={ServiceEdit}
        create={ServiceCreate}
        options={{ label: "Услуги", isMenu: false }}
      />
      <Resource
        icon={Shop}
        name={`customers`}
        list={CustomerList}
        edit={CustomerEdit}
        create={CustomerCreate}
        options={{ label: "Посетители", isMenu: false }}
      />
      <CustomRoutes>
        <Route path="/calendar/:date" element={<Calendar roomId={room?.id || 0} />} />
        <Route path="/devices" element={<Devices roomId={room?.id || 0} />} />
      </CustomRoutes>
    </Admin>
  )
}
