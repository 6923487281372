import {
  Datagrid,
  BooleanField,
  List,
  TextField,
  FunctionField,
  ImageField,
  ReferenceArrayField,
  ReferenceField,
  DateField,
  SearchInput,
  DateInput,
  BooleanInput,
  NumberField,
  ReferenceInput,
  EmailField,
  useListContext,
  useRecordContext,
  Identifier,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin"

import { ApiService } from "../../types/common"
import { durationFormat } from "../../utils/time-helpers"


const serviceFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    source="serviceCategory"
    reference="serviceCategories"
    alwaysOn
  >
    <AutocompleteInput optionText="name" label="Категория"/>
  </ReferenceInput>,
  <BooleanInput label="Статус" source="status" defaultValue={true} />,
]

export const ServiceList = () => {
  function DurationField() {
    const record = useRecordContext()
  
    return (
      <FunctionField
        label="Длительность"
        render={(record: ApiService) => durationFormat(record.duration)}
      />
    )
  }

  return (
    <List hasCreate hasEdit filters={serviceFilters}>
      <Datagrid rowClick="edit">
        <TextField source="name" label="Название" />
        <DurationField />
        <ReferenceField source="serviceCategory.id" reference="serviceCategories" label="Категория">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="status" label="Состояние" />
      </Datagrid>
    </List>
  )
}