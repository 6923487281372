export const getDurationParts = (duration: number) => {
  const hours = Math.floor(duration / 60)
  const minutes = hours ? duration % (hours * 60) : duration
  
  return [hours, minutes]
}

export const durationFormat = (duration: number) => {
  const parts = getDurationParts(duration)
  const [hours, minutes] = parts
  let result = []
  if (parts[0]) {
    result.push(`${hours}ч`)
  }
  if (minutes) {
    result.push(`${minutes}м`)
  }
  
  return result.join(' ')
}

