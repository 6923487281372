import React, { ReactEventHandler, useEffect, useMemo, useRef, useState } from 'react'
import {FormProvider, useFormContext, useForm, useWatch} from 'react-hook-form'

import {
  Create,
  Edit,
  Form,
  SimpleForm,
  TextInput,
  DateTimeInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  AutocompleteInput,
  required,
  useRecordContext,
  useDataProvider,
  useRecordSelection,
  Identifier,
  RaRecord,
  useGetOne,
  useGetList,
  useInput,
  BooleanInput,
} from 'react-admin'
import { ApiRecord, Record } from '../../types/common'
import { getDurationParts } from '../../utils/time-helpers'
import { DurationInput } from '../../components/DurationInput/input'


const ServiceSelect = ({ dp, setDuration }: any) => {

  const handleSelectService = (e: RaRecord<Identifier> | React.ChangeEvent<HTMLInputElement>) => {
    dp.getOne('services', { id: e.target.value }).then(({ data }: any) => {
      setDuration(data?.duration)
    })
  }

  return (
    <ReferenceInput perPage={100} source="service.id" reference="services" isRequired>
      <SelectInput
        label="Услуга"
        optionText={'name'}
        onChange={handleSelectService}
        isRequired
      />
    </ReferenceInput>
  )
}

const CustomerSelect = () => {

  return (
    <ReferenceInput source="customer.id" reference="customers" isRequired>
      <AutocompleteInput
        label="Посетитель"
        optionText={'firstName'}
        isRequired
        validate={required()}
      />
    </ReferenceInput>
  )
}

const RecordForm = ({ dp }: any) => {
  const { getValues, setValue } = useFormContext()

  const [duration, setDuration] = useState(0)

  useEffect(() => {
    setValue('duration', duration)
  }, [duration])


  return (
    <>
      <ServiceSelect dp={dp} setDuration={setDuration} />
      <CustomerSelect />
      <DateTimeInput
        source="datetime"
        label="Время"
        isRequired
        validate={required()}
      />
      <TextInput
        source="duration"
        label="Продолжительность"
        isRequired
        validate={required()}
        style={{display:'none'}}
      />
      <DurationInput duration={duration} />
      <BooleanInput source="status" />
    </>
  )
}

export const RecordEditForm = ({
  roomId,
  recordId,
  onSubmit,
}: {
  roomId: number
  recordId: number | null
  onSubmit: Function
}) => {
  const dp = useDataProvider()
  const [record, setRecord] = useState<Record | null>(null)

  useEffect(() => {
    dp.getOne(`rooms/${roomId}/records`, { id: recordId }).then(({ data }: { data: Record }) => {
      console.log('data', data)
      setRecord(data)
    })
  }, [])

  const handleSubmit = (values: any) => {
    dp.update(`rooms/${roomId}/records`, { id: values.id, data: values, previousData: {} })
    .then(({ data }) => {
      onSubmit()
    })
    .catch(error => {
      //showNotification(error.message, "error");
    })
    .finally(() => {
      // Dispatch an action letting react-admin know a API call has ended
      //fetchEnd();
    })

  }


  return (
      <SimpleForm  defaultValues={record} onSubmit={handleSubmit} mode="onBlur" reValidateMode="onBlur">
        <RecordForm dp={dp} />
      </SimpleForm>
  )
}
