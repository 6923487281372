import {
  Datagrid,
  BooleanField,
  List,
  TextField,
  FunctionField,
  ImageField,
  ReferenceArrayField,
  ReferenceField,
  DateField,
  SearchInput,
  DateInput,
  BooleanInput,
  NumberField,
  ReferenceInput,
  EmailField,
  useListContext,
  useRecordContext,
  Identifier,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin"

const serviceFilters = [
  <SearchInput source="q" alwaysOn />,
  <BooleanInput label="Статус" source="status" defaultValue={true} />,
]

export const ServiceCategoryList = () => {

  return (
    <List hasCreate hasEdit filters={serviceFilters}>
      <Datagrid rowClick="edit">
        <TextField source="name" label="Название" />
        <BooleanField source="status" label="Состояние" />
      </Datagrid>
    </List>
  )
}