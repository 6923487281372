import { useMemo, useState } from 'react'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import {
  CalendarContainer,
  CalendarControls,
  ControlButtonWrapper,
  PrevMonthButton,
  NextMonthButton,
  CurrentInfoWrapper,
  CalendarWeekDays,
  CalendarGridItems,
  DayGridItem
} from './styles'

dayjs.extend(isToday)
const todayDate = dayjs(dayjs().format('YYYY-MM-DD')).toDate()


function DateSwitcher({ onChange }: { onChange: Function }) {
  const [selectedYear, setSelectedYear] = useState<number>(dayjs(todayDate).year())
  const [selectedMonth, setSelectedMonth] = useState<number>(dayjs(todayDate).month())
  const [selectedDate, setSelectedDate] = useState<Date>(todayDate)
  
  
  const daysInMonth = useMemo(() => {
    return dayjs(new Date(selectedYear, selectedMonth, 1)).daysInMonth()
  }, [selectedYear, selectedMonth])

  const firstDayOfWeek = useMemo(() => {
    return new Date(selectedYear, selectedMonth, 1).getDay()
  }, [selectedYear, selectedMonth])

  const lastDayOfWeek = useMemo(() => {
    const lastDayOfMonthFormatDate = `${selectedYear}-${selectedMonth + 1}-${daysInMonth}`
    return dayjs(lastDayOfMonthFormatDate).toDate().getDay()
  }, [selectedYear, selectedMonth, daysInMonth])

  type MonthCalendar = {
    day: number
    date: Date
    isToday: boolean
    isSelected: boolean
    monthOffset: number
  }[]

  const monthCalendar: MonthCalendar = useMemo(() => {
    const results: MonthCalendar = []

    for (let i = -(firstDayOfWeek + (firstDayOfWeek === 0 ? 7 : 0) - 1); i < 0; i++) {
      const firstDayOfMonthFormatDate = `${selectedYear}-${selectedMonth + 1}-1`
      const date = dayjs(firstDayOfMonthFormatDate).subtract(Math.abs(i), 'day').toDate()
      const day = dayjs(date).date()
      const isToday = dayjs(date).isToday()
      const isSelected = selectedDate.getTime() === dayjs(date).toDate().getTime()

      results.push({ day, date, isToday, isSelected, monthOffset: -1 })
    }

    for (let i = 0; i < daysInMonth; i++) {
      const formatDate = `${selectedYear}-${selectedMonth + 1}-${i + 1}`
      const date = dayjs(formatDate).toDate()
      const isToday = dayjs(formatDate).isToday()
      const isSelected = selectedDate.getTime() === dayjs(formatDate).toDate().getTime()
      
      results.push({ day: i + 1, date, isToday, isSelected, monthOffset: 0 })
    }

    for (let i = 1; i <= (7 * (lastDayOfWeek === 0 ? 0 : 1) - lastDayOfWeek); i++) {
      const lastDayOfMonthFormatDate = `${selectedYear}-${selectedMonth + 1}-${daysInMonth}`
      const date = dayjs(lastDayOfMonthFormatDate).add(Math.abs(i), 'day').toDate()
      const day = dayjs(date).date()
      const isToday = dayjs(date).isToday()
      const isSelected = selectedDate.getTime() === dayjs(date).toDate().getTime()

      results.push({ day, date, isToday, isSelected, monthOffset: 1 })
    }

    return results
  }, [selectedYear, selectedMonth, selectedDate])

  const handleSelectDate = (date: Date) => {
    setSelectedDate(date)
    onChange(date)
  }
  
  const handleClickPrevMonth = () => {
    const formatDate = `${selectedYear}-${selectedMonth + 1}-${1}`
    const plusMonthDate = dayjs(formatDate).subtract(1, 'month')
    const resultYear = dayjs(plusMonthDate).year()
    const resultMonth = dayjs(plusMonthDate).month()
    setSelectedYear(resultYear)
    setSelectedMonth(resultMonth)
  }

  const handleClickNextMonth = () => {
    const formatDate = `${selectedYear}-${selectedMonth + 1}-${1}`
    const plusMonthDate = dayjs(formatDate).add(1, 'month')
    const resultYear = dayjs(plusMonthDate).year()
    const resultMonth = dayjs(plusMonthDate).month()
    setSelectedYear(resultYear)
    setSelectedMonth(resultMonth)
  }

  const months = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
  ]

  return (
    <CalendarContainer>
      <CalendarControls>
        <ControlButtonWrapper onClick={handleClickPrevMonth}>
          <PrevMonthButton />
        </ControlButtonWrapper>
        <CurrentInfoWrapper>
          {`${months[selectedMonth]} ${selectedYear}`}
        </CurrentInfoWrapper>
        <ControlButtonWrapper onClick={handleClickNextMonth}>
          <NextMonthButton />
        </ControlButtonWrapper>
      </CalendarControls>
      <CalendarWeekDays>
        {['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'].map(item => (
          <span>{item}</span>
        ))}
      </CalendarWeekDays>
      <CalendarGridItems>
        {monthCalendar.map((item, i) => {
          return (
            <DayGridItem
              key={i}
              isToday={item.isToday}
              isSelected={item.isSelected}
              isSelectedMonth={item.monthOffset === 0}
              onClick={() => handleSelectDate(item.date)}
            >
              {item.day}
            </DayGridItem>
        )})}
      </CalendarGridItems>
    </CalendarContainer>
  )
}

export default DateSwitcher