import {
  Edit,
  Form,
  SimpleForm,
  TextInput,
  DateTimeInput,
  BooleanInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  AutocompleteInput,
  required,
} from 'react-admin'


  
export const CustomerEdit = (props: any) => (
  <Edit>
    <SimpleForm>
      <TextInput label="Имя" source="firstName" />
      <TextInput label="Телефон" source="phone" />
      <TextInput label="Email" source="email" />
      <BooleanInput label="Статус" source="status" />
    </SimpleForm>
  </Edit>
)
